import React from 'react';
import './App.css';

class App extends React.Component {
  updateVH() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  setupVHHelper() {
    this.updateVH();
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      this.updateVH();
    });
  }

  componentDidMount() {
    document.title = "Paul Semionov - iOS Developer";

    this.setupVHHelper();
  }

  render() {
    return (
      <main>
        <div>
          <img class="round" src="duffpod.png" style={{width:'200px',height:'200px'}}></img>
        </div>
        <div class="text-block">
          <p class="title">Paul Semionov</p>
          <p class="subtitle">iOS Developer</p>
        </div>
        <div>
          <span>
            <a href="mailto:me@duffpod.com">
              <img src="apple-mail.png" style={{width:'38px',height:'38px'}}></img>
            </a>
          </span>
          <span>
            <a href="https://www.linkedin.com/in/duffpod/" target="_blank" rel="noopener noreferrer">
              <img src="linkedin.png" style={{width:'38px',height:'38px'}}></img>
            </a>
          </span>
          <span>
            <a href="https://tg.duffpod.com/">
              <img src="telegram.png" style={{width:'38px',height:'38px'}}></img>
            </a>
          </span>
          <span>
            <a href="https://github.com/duffpod/" target="_blank" rel="noopener noreferrer">
              <img src="github.png" style={{width:'38px',height:'38px'}}></img>
            </a>
          </span>
        </div>
      </main>
    );
  }
}

export default App;
